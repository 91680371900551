import React from 'react'
import Reveal from 'react-awesome-reveal'
import { fadeInRightShorter } from '../utils/keyframes'

const PersonalCare = () => {
    return (
        <div className='bg-gradient'>
            <div className="row m-0 justify-content-end align-items-center">
                <div className="col-lg-6 text-center text-white py-5">
                    <Reveal keyframes={fadeInRightShorter} duration={1000}><h1 className='fw-lighter'>An Innovative Personal Care Brand</h1></Reveal>
                    <Reveal keyframes={fadeInRightShorter} duration={1000} delay={200}><h3 className='mb-5 pb-lg-5'>Who cares about your Health</h3></Reveal>

                    <div className="d-flex gap-20 flex-wrap pt-lg-5 justify-content-center">
                        <div className='personal-col'>
                            <Reveal keyframes={fadeInRightShorter} duration={1000} delay={400}>
                                <img className='personal-icon' src={require('../assets/images/napkin-icon.png')} />
                                <div className='mt-3'>Better Pads</div>
                            </Reveal>
                        </div>
                        <div className='personal-col'>
                            <Reveal keyframes={fadeInRightShorter} duration={1000} delay={600}>
                                <img className='personal-icon' src={require('../assets/images/disposal-icon.png')} />
                                <div className='mt-3'>Hassle free Disposal</div>
                            </Reveal>
                        </div>
                        <div className='personal-col'>
                            <Reveal keyframes={fadeInRightShorter} duration={1000} delay={800}>
                                <img className='personal-icon' src={require('../assets/images/packs-icons.png')} />
                                <div className='mt-3'>Customized Packs</div>
                            </Reveal>
                        </div>
                        <div className='personal-col'>
                            <Reveal keyframes={fadeInRightShorter} duration={1000} delay={1200}>
                                <img className='personal-icon' src={require('../assets/images/customer-icon.png')} />
                                <div className='mt-3'>Customer Approved</div>
                            </Reveal>
                        </div>
                    </div>
                </div>
                <div className="col-lg-5 p-0">
                    <Reveal keyframes={fadeInRightShorter} duration={1000}>
                        <img src={require('../assets/images/personal-care.png')} className='w-100 personal-care-image' />
                    </Reveal>
                </div>
            </div>
        </div>
    )
}

export default PersonalCare