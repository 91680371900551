import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'react-bootstrap/Modal';
import { useForm } from 'react-hook-form';
import { FaTimes } from 'react-icons/fa';

const ContactModal = ({ show, onHide }) => {
    const { register, handleSubmit, formState: { errors } } = useForm();

    const [isSuccess, setSuccess] = useState(false)
    const [loading, setLoading] = useState(false)
    const [payment, setPayment] = useState(false)

    // Handle form submission
    const onSubmit = formValues => {
        setLoading(true)
        formValues.name = formValues.firstName + " " + formValues.lastName
        axios.post('https://ejnarstudios.com/ej-mailer/api/send-mail', formValues).then(response => {
            if (response.data.status) {
                setSuccess(true)
            } else {
                setSuccess(false)
            }
        }).finally(() => {
            setLoading(false)
        })
    };

    useEffect(() => {
        const rzpPaymentForm = document.getElementById("rzp_payment_form");
        console.log(rzpPaymentForm)
        if (rzpPaymentForm) {

            const script = document.createElement("script");
            script.src = "https://checkout.razorpay.com/v1/payment-button.js";
            script.async = true;
            script.dataset.payment_button_id = "pl_OtR6zWweapfg3C";

            console.log(rzpPaymentForm.childNodes.length, rzpPaymentForm.childNodes)
            if (rzpPaymentForm.childNodes.length === 0) {
                rzpPaymentForm.appendChild(script);
            }

        }

    }, [show]);

    return (
        <Modal show={show} size="xl" centered>
            <Modal.Body className='p-0 rounded overflow-hidden'>

                <div className="row">
                    <div className="col-lg-5 p-0 d-lg-block d-none bg-contact"></div>
                    <div className="col-lg-7 p-5 position-relative bg-contact-mobile">
                        <button
                            onClick={onHide}
                            className='btn btn-outline-dark border-0 rounded-pill float-end close-button'
                        >
                            <FaTimes size={25} />
                        </button>
                        <div className="col-lg-10 mx-auto p-0">

                            {
                                isSuccess ?
                                    <div className="row text-center align-items-center" style={{ minHeight: 400 }}>
                                        <div className="col-12">
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={100}
                                                height={100}
                                                aria-hidden="true"
                                                className="d-block mx-auto"
                                                viewBox="0 0 64 64"
                                            >
                                                <circle cx={32} cy={32} r={30} fill="#4bd37b" />
                                                <path fill="#fff" d="M46 14 25 35.6l-7-7.2-7 7.2L25 50l28-28.8z" />
                                            </svg>
                                            <h2 className="mb-2 mt-5">Thanks for reaching out!</h2>
                                            <p className="text-secondary lead fw-bold">We’ve received your inquiry and will get back to you within 24 hour</p>
                                        </div>
                                    </div>
                                    :
                                    <>

                                        <div className="text-center">
                                            <h5 className='fw-bold'>Free Shipping</h5>
                                            <p className='small fw-light'>Enjoy free shipping and visit our site for exclusive discounts and offers!</p>
                                        </div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            {
                                                payment ?
                                                    <img src={require('../assets/images/payment.jpg')} className='col-12 p-0 col-lg-8 mx-auto d-block my-3' />
                                                    :
                                                    <>
                                                        <Form.Group controlId="firstName" className='mb-3'>
                                                            <Form.Label>First Name</Form.Label>
                                                            <Form.Control
                                                                size='sm'
                                                                type="text"
                                                                placeholder="Enter your first name"
                                                                {...register('firstName', { required: 'First name is required' })}
                                                            />
                                                            {errors.firstName && <Form.Text className="text-danger">{errors.firstName.message}</Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="lastName" className='mb-3'>
                                                            <Form.Label>Last Name</Form.Label>
                                                            <Form.Control
                                                                size='sm'
                                                                type="text"
                                                                placeholder="Enter your last name"
                                                                {...register('lastName', { required: 'Last name is required' })}
                                                            />
                                                            {errors.lastName && <Form.Text className="text-danger">{errors.lastName.message}</Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="mobile" className='mb-3'>
                                                            <Form.Label>Phone Number</Form.Label>
                                                            <Form.Control
                                                                size='sm'
                                                                type="tel"
                                                                placeholder="Enter your phone number"
                                                                {...register('mobile', { required: 'Phone number is required' })}
                                                            />
                                                            {errors.mobile && <Form.Text className="text-danger">{errors.mobile.message}</Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="email" className='mb-3'>
                                                            <Form.Label>Email Address</Form.Label>
                                                            <Form.Control
                                                                size='sm'
                                                                type="email"
                                                                placeholder="Enter your email address"
                                                                {...register('email', {
                                                                    required: 'Email is required',
                                                                    pattern: {
                                                                        value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                                        message: 'Invalid email address'
                                                                    }
                                                                })}
                                                            />
                                                            {errors.email && <Form.Text className="text-danger">{errors.email.message}</Form.Text>}
                                                        </Form.Group>

                                                        <Form.Group controlId="address" className='mb-3'>
                                                            <Form.Label>Message</Form.Label>
                                                            <Form.Control
                                                                size='sm'
                                                                as="textarea"
                                                                rows={3}
                                                                placeholder="Enter your Message"
                                                                {...register('message', { required: 'Message is required' })}
                                                            />
                                                            {errors.message && <Form.Text className="text-danger">{errors.message.message}</Form.Text>}
                                                        </Form.Group>
                                                    </>
                                            }

                                            <center>
                                                <div className="d-inline-flex gap-2 align-items-center">
                                                    <Button variant="dark" onClick={() => setPayment(false)} loading={loading} disabled={loading} className='py-2 px-lg-5' type="submit">
                                                        {loading ? "Loading..." : "Enquiry"}
                                                    </Button>
                                                    <form style={{ marginTop: 5 }} id="rzp_payment_form"></form>
                                                </div>
                                            </center>
                                        </Form>
                                    </>
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ContactModal;
