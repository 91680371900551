import React from 'react';
import AboutUs from '../Components/AboutUs';
import Banner from '../Components/Banner';
import Header from '../Components/Header';
import InfoFifine from '../Components/InfoFifine';
import OurProducts from '../Components/OurProducts';
import OurTeam from '../Components/OurTeam';
import PersonalCare from '../Components/PersonalCare';
import Testimonials from '../Components/Testimonials';

export default function Home() {
    return (
        <div>
            <div className='position-relative'>
                <Header />
                <Banner />
            </div>
            <AboutUs />
            <OurProducts />
            <InfoFifine />
            <OurTeam />
            <PersonalCare />
            <Testimonials />
        </div>
    )
}
