import React from 'react';
import Slider from 'react-slick';

// let prevPath;

function FaAngleRight(props) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="0.48em" height="1em" viewBox="0 0 608 1280" {...props}><g transform="translate(608 0) scale(-1 1)"><path fill="currentColor" d="M595 288q0 13-10 23L192 704l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L23 727q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23"></path></g></svg>);
}

export function FaAngleLeft(props) {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="0.48em" height="1em" viewBox="0 0 608 1280" {...props}><path fill="currentColor" d="M595 288q0 13-10 23L192 704l393 393q10 10 10 23t-10 23l-50 50q-10 10-23 10t-23-10L23 727q-10-10-10-23t10-23l466-466q10-10 23-10t23 10l50 50q10 10 10 23"></path></svg>);
}
function Carousel(props) {
    const { options } = props;
    const defaultOptions = {
        dots: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000
    };
    let settings = Object.assign({}, defaultOptions, options);

    return (
        props.children ?
            props.children.length > 0 || (props.children && props.children.length === undefined) ?
                <Slider {...settings}>{props.children}</Slider >
                : ""
            : ""
    );
}

export default React.memo(Carousel);