import CottonXl from '../assets/images/Cotton-XL.svg'
import CottonXXl from '../assets/images/Cotton-XXL.svg'
import HerbalXl from '../assets/images/Herbal-XL.svg'
import HerbalXXl from '../assets/images/Herbal-XXL.svg'
export const introSlider = {
    nav: false,
    dots: true,
    loop: false,
    items: 1,
    autoplay: false
}


export const products = [
    {
        name: 'Sanitary Napkin | Cotton',
        description: `Sanitary napkins made from cotton offer comfort, absorbency, and environmental sustainability. Cotton's softness and breathability provide a gentle experience while ensuring efficient absorption. These napkins are hypoallergenic and suitable for sensitive skin. Additionally, their biodegradable nature reduces environmental impact compared to synthetic options, making them a sustainable choice for menstrual hygiene.`,
        products: [
            {
                image: CottonXl,
                padPack: 12,
                strikePrice: 400,
                salePrice: 250,
            },
            {
                image: CottonXXl,
                padPack: 12,
                strikePrice: 429,
                salePrice: 280,
            }
        ],
    },
    {
        name: 'Sanitary Napkin | Herbal',
        description: `Herbal sanitary napkins incorporate natural ingredients like Aloe vera, Neem and Vettiveru, known for their soothing and therapeutic properties. These napkins offer comfort, hygiene, and freshness during menstruation, addressing concerns such as irritation, odor, and discomfort. By utilizing herbal extracts, they provide a natural and gentle alternative to conventional pads, promoting overall well-being and confidence.`,
        products: [
            {
                image: HerbalXl,
                padPack: 12,
                strikePrice: 429,
                salePrice: 280,
            },
            {
                image: HerbalXXl,
                padPack: 12,
                strikePrice: 449,
                salePrice: 300,
            },
        ],
    }
]

export const testimonials = [
    {
        content: `I was looking for a biodegradable sanitary napkin & I found many options, but none worked for me. I gave Fefine napkin a try. It literally blew my mind. These pads feel really soft and natural without that plasticy feel. Individual disposal bags are really convenient. Finally a brand with a great product and a greater initiative. Go for it.`,
        name: 'Reshma Banu',
        designation: `Customer`,
        image: 'https://lh3.googleusercontent.com/a-/ALV-UjUXYO9MryhRDHlrkNZbbhVo8aBhxGiRb2NhKeJViSu9Le0xyds=w200-h200-p-rp-mo-br100'
    },
    {
        content: `Quality of the product is really good.  Cotton and herbal options are more soft and comfort.`,
        designation: `Customer`,
        name: 'Usha Balamurugan',
        image: 'https://lh3.googleusercontent.com/a/ACg8ocJ-5jQgRtXybWIIDpKdCKBsQ8yHHC01HgIa0xGAKrEj6whqYA=w200-h200-p-rp-mo-br100'
    },
    {
        content: `Best sanitary pads at an affordable price. Doesn’t cause any irritation.`,
        name: `Sandhya Josphin`,
        designation: `Customer`,
        image: "https://lh3.googleusercontent.com/a-/ALV-UjXv6rFZ7nDQvtED_kfaJl1t0arY0jTEwOhTVKgXTNUy4ODYXG0=w200-h200-p-rp-mo-br100"
    },
    {
        content: `Fefine Sanitary Pads are amazing. I’ve noticed a significant reduction in rashes.`,
        name: `Suhasini Kalyana Kumar`,
        designation: `Customer`,
        image: "https://lh3.googleusercontent.com/a-/ALV-UjWNUE-gnFK9JVRRVeEk3vjiTB3eg4M739LubAX5JP2rVjdsHdiQ=w200-h200-p-rp-mo-br100"
    },
    {
        content: `I recently switched to fefine sanitary pads and I’m impressed. They’re incredibly soft and comfortable`,
        name: `Pooja K`,
        designation: `Customer`,
        image: "https://lh3.googleusercontent.com/a/ACg8ocLzVFFsTF1WHbg4yVywOsgFH3qS8JGi6qz-dal--kbFQdmmUw=w200-h200-p-rp-mo-br100"
    },
    {
        content: `Fefine bio sanitary pads are comfortable and gentle on skin. It also widthstands heavy flow for a pretty good duration. Definitely a good buy.`,
        name: `Deepa Lax`,
        designation: `Local Guide`,
        image: "https://lh3.googleusercontent.com/a/ACg8ocJ9Dhz3xaqgzb7TuY-VqAe9OccF9sy9IPT-dQgefLfUtuLt0w=w200-h200-p-rp-mo-ba3-br100"
    },
]