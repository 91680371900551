import React from 'react';
import Reveal from 'react-awesome-reveal';
import { RiArrowLeftSLine, RiArrowRightSLine } from "react-icons/ri";
import { testimonials } from '../utils';
import { fadeInRightShorter } from '../utils/keyframes';
import Carousel from './Carousel';

const Testimonials = () => {
    const PrevArrow = (props) => (
        <button {...props} className="client-arrow-left">
            <RiArrowLeftSLine size={35} />
        </button>
    );

    const NextArrow = (props) => (
        <button {...props} className="client-arrow-right">
            <RiArrowRightSLine size={35} />
        </button>
    );

    const settings = {
        dots: true,
        arrows: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
    }

    return (
        <section>
            <div className="container">
                <Reveal keyframes={fadeInRightShorter}>
                    <h5 className='fw-bold text-center mb-4'>Our testimonials</h5>
                </Reveal>
                <Carousel options={settings}>
                    {testimonials.map((item, i) =>
                    (<div key={i} className='p-lg-5 p-3 text-lg-start text-center border border-gray'>
                        <div className="row justify-content-center align-items-center">
                            <div className="col-md-2">
                                <Reveal keyframes={fadeInRightShorter} duration={1000}>
                                    <img src={item.image} width={150} className='mx-auto mb-3' />
                                </Reveal>
                            </div>
                            <div className="col-md-9">
                                <Reveal keyframes={fadeInRightShorter} delay={200} duration={1000}>
                                    <p className='text-md'>{item.content}</p>
                                </Reveal>
                                <Reveal keyframes={fadeInRightShorter} delay={400} duration={1000}>
                                    <div>
                                        <b className='lead fw-bold'>{item.name}</b>
                                        <span className="fw-light ms-2 text-primary">{item.designation}</span>
                                    </div>
                                </Reveal>
                            </div>
                        </div>
                    </div>)
                    )}
                </Carousel>
            </div>
        </section>
    )
}

export default Testimonials