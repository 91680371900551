import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { FaBars } from 'react-icons/fa';
import { LiaTimesSolid } from 'react-icons/lia';

const NavLinks = () => {
    return (
        <div className="nav-links">
            <a href="#">Home</a>
            <a href="#about-us">About us</a>
            <a href="#products">Our products</a>
            <a href="#contact-us">Contact us</a>
        </div>
    )
}

const Header = () => {
    const [opened, setOpened] = useState(true)
    const isShow = window.innerWidth > 768;
    return (
        <header>
            <div className="container">
                <nav>
                    <div className="brand">
                        <img src={require('../assets/brand/logo-white.png')} />
                    </div>
                    {isShow && <NavLinks />}
                    <div className="nav-cta gap-0">
                        <a href="#products" className='btn btn-primary'>Buy now</a>
                        {
                            !isShow &&
                            <Dropdown onToggle={() => setOpened(!opened)}>
                                <Dropdown.Toggle variant='primary-light' className='rounded-pill border-lg btn btn-outline-light ms-3'>
                                    {opened ? <FaBars /> : <LiaTimesSolid />}
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <NavLinks />
                                </Dropdown.Menu>
                            </Dropdown>
                        }
                    </div>
                </nav>
            </div>
        </header>
    )
}

export default Header