import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import './App.scss';
import Layout from './Layout';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route Component={Layout}>
          <Route index Component={Home}></Route>
          <Route path='privacy-policy' Component={PrivacyPolicy}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App