import React, { useState } from 'react'
import Reveal, { Fade } from 'react-awesome-reveal'
import { products } from '../utils'
import { fadeInRightShorter, fadeInUpShorter } from '../utils/keyframes'
import ContactModal from './ContactModal'

const OurProducts = () => {
    const [isOpen, setOpen] = useState(false)

    return (
        <section className='bg-primary-light' id='products'>
            <ContactModal show={isOpen} onHide={() => setOpen(!isOpen)} />

            <div className="px-lg-5 px-3">
                <Fade direction='up'><h4 className='fw-bold text-center'><u>Check out our Products</u></h4></Fade>
                <Fade direction='down'><p className='fw-medium mb-lg-5 text-center'>
                    Enjoy <span className='text-primary fw-bold fs-4'>Free Shipping</span> Across India </p></Fade>
                <div className="row px-lg-5">
                    {
                        products.map((item, i) => (
                            <div key={i} className="col-md-6 py-3">
                                <div className="row">
                                    {
                                        item.products.map((data, j) => (
                                            <div className="col-md-6 p-1" key={j}>
                                                <div className="product-image-wrapper">
                                                    <div className="product-price-info">
                                                        <Reveal keyframes={fadeInUpShorter}>
                                                            <div className='pad-pack'>{data.padPack} Pad | Pack</div>
                                                            <div><strike className="strike-price">INR {data.strikePrice}</strike></div>
                                                            <div className='sale-price'>INR.{data.salePrice}</div>
                                                            <div>
                                                                <button className='btn btn-primary btn-sm px-3' onClick={() => setOpen(!isOpen)}>Buy now</button>
                                                            </div>
                                                        </Reveal>
                                                    </div>
                                                    <center>
                                                        <Reveal keyframes={fadeInRightShorter}>
                                                            {console.log(data.image)}
                                                            <img src={data.image} className='w-100' />
                                                        </Reveal>
                                                    </center>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                                <Reveal delay={200} duration={1000} keyframes={fadeInRightShorter}><h4 className='fw-bold my-3'>{item.name}</h4></Reveal>
                                <Reveal delay={300} duration={1000} keyframes={fadeInRightShorter}><p className='m-0 text-justify text-md'>{item.description}</p></Reveal>
                            </div>
                        ))
                    }
                </div>
            </div>
        </section>
    )
}

export default OurProducts