import React from 'react'
import { Fade } from 'react-awesome-reveal'

export default function OurTeam() {
    return (
        <section className='bg-primary-light' >
            <div className="container">
                <Fade direction='up'><h4 className='fw-bold mb-lg-5 text-center'>Our Greenviron Team</h4></Fade>
                <div className="row mt-100">
                    {
                        window.innerWidth < 768 ?
                            <div className="col-md-4 my-2  move-top">
                                <div className="card card-body py-5 px-4 lead text-center">
                                    <div> <b className='fw-bold fs-3'>K. Ramanathan</b></div>
                                    <div> Consultant & advisor</div>
                                </div>
                            </div>
                            : <div className="col-md-4 my-2 ">
                                <div className="card card-body py-5 px-4 lead text-center">
                                    <div> <b className='fw-bold'>R. Vetriselvi </b>BE, MBA</div>
                                    <div> Production executive</div>
                                </div>
                            </div>
                    }
                    {
                        window.innerWidth > 768 ?
                            <div className="col-md-4 my-2  move-top">
                                <div className="card card-body py-5 px-4 lead text-center">
                                    <div> <b className='fw-bold fs-3'>K. Ramanathan</b></div>
                                    <div> Consultant & advisor</div>
                                </div>
                            </div>
                            : <div className="col-md-4 my-2 ">
                                <div className="card card-body py-5 px-4 lead text-center">
                                    <div> <b className='fw-bold'>R. Vetriselvi </b>BE, MBA</div>
                                    <div> Production executive</div>
                                </div>
                            </div>
                    }

                    <div className="col-md-4 my-2 ">
                        <div className="card card-body py-5 px-4 lead text-center">
                            <div> <b className='fw-bold'>A. Damodaran </b>, MCA</div>
                            <div> Marketing executive</div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
