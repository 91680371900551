import React, { useEffect } from 'react';
import { FaArrowLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';
export default function PrivacyPolicy() {
    useEffect(() => window.scroll(0, 0), []);
    return (
        <div>
            <div className="shadow">
                <div className="container">
                    <div className='d-flex justify-content-between align-items-center'>
                        <img src={require('../assets/images/footer-logo.png')} />
                        <Link to={'/'}> <FaArrowLeft /> Back</Link>
                    </div>
                </div>
            </div>
            <section className='container'>
                <h1><strong>Privacy Policy:</strong></h1>
                <p><strong><br /> </strong>FeFine Sanitary Napkins. We value your privacy and are committed to protecting your personal information. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you visit our website. Please read this policy carefully.</p>
                <p><br /> <strong>Information We Collect:</strong></p>
                <ul>
                    <li><em>Personal Information:</em> When you make a purchase, we collect personal information such as your name, email address, shipping address, and payment information.</li>
                    <li><em>Usage Data:</em> We collect information about your interaction with our website, such as your IP address, browser type, pages viewed, and the date and time of your visit.</li>
                    <li><em>Cookies and Tracking Technologies:</em> We use cookies and similar tracking technologies to enhance your experience on our website, analyze site usage, and provide personalized content.</li>
                </ul>
                <p><strong>How We Use Your Information</strong>: We use your information for the following purposes:</p>
                <ul>
                    <li><strong>To Process Transactions:</strong> To process and fulfill your orders, including shipping and billing.</li>
                    <li><strong>To Improve Our Website:</strong> To analyze usage trends and improve our website&rsquo;s functionality and user experience.</li>
                    <li><strong>To Communicate with You:</strong> To send you updates, promotional materials, and information about our products and services.</li>
                    <li><strong>To Ensure Security:</strong> To protect against fraud, unauthorized transactions, and other illegal activities</li>
                    <li><strong>Sharing Your Information:</strong> We do not sell or rent your personal information to third parties. We may share your information with:</li>
                    <li><strong>Service Providers:</strong> Third-party vendors who assist with payment processing, order fulfillment, and email communications.</li>
                    <li><strong>Legal Requirements:</strong> Authorities or regulatory bodies if required by law or to protect our rights or property. <br /> <br /> <strong>Data Security : </strong>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or electronic storage is 100% secure.</li>
                </ul>
                <p><br /> <strong>Changes to This Privacy Policy :</strong> We may update this Privacy Policy from time to time. Any changes will be posted on this page with an updated effective date. Your continued use of the website after any changes indicates your acceptance of the updated policy.</p>
                <p><br /> <strong>Contact Us: </strong>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>
                <ul>
                    <li><strong>Phone:</strong> +91 8807855092</li>
                    <li><strong>Email:</strong> care@fefine.in</li>
                    <li><strong>Address:</strong> Plot No. 17, VIP Nagar Phase IV, Thirukachur, Maraimalai Nagar, Chennai-603209.</li>
                </ul>
                <p>Thank you for choosing Fefine Sanitary Napkin. Your privacy is important to us.<br /> </p>
                <p>&nbsp;</p>
            </section>
        </div>
    )
}
