import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Reveal from 'react-awesome-reveal';
import { HiArrowLongLeft, HiArrowLongRight } from "react-icons/hi2";
import { fadeInRightShorter, fadeInUpShorter } from '../utils/keyframes';
import Carousel from './Carousel';

const Banner = () => {
    const sliderRef = useRef(null);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const [totalSlides, setTotalSlides] = useState(0);

    useEffect(() => {
        if (sliderRef.current) {
            setTotalSlides(sliderRef.current.props.children.length);
        }
    }, []);

    const PrevArrow = forwardRef((props, ref) => (
        <button ref={ref} {...props} onClick={handlePrevClick} className='text-secondary btn rounded-circle'>
            <HiArrowLongLeft size={25} />
        </button>
    ));

    const NextArrow = forwardRef((props, ref) => (
        <button ref={ref} {...props} onClick={handleNextClick} className='text-secondary btn rounded-circle'>
            <HiArrowLongRight size={25} />
        </button>
    ));


    const handlePrevClick = () => {
        sliderRef.current.slickPrev();
    };

    const handleNextClick = () => {
        sliderRef.current.slickNext();
    };


    const options = {
        ref: sliderRef,
        dots: false,
        arrows: false,
        afterChange: (index) => {
            setActiveSlideIndex(index);
        }
    }

    return (
        <div className='hero-banner'>
            <div className="w-100">
                <div className="container">
                    <Carousel options={options}>
                        <div className="p-2">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-6 pt-lg-5">
                                    <Reveal keyframes={fadeInRightShorter} delay={200} duration={1000}>
                                        <h1 className='fw-lighter'>The One <b>That suits</b></h1>
                                        <h1>Your Needs</h1>
                                    </Reveal>
                                    <Reveal keyframes={fadeInRightShorter} delay={400} duration={1000}>
                                        <p className='fw-normal'>Dedicated to your health and hygiene Biodegradable cotton pads</p>
                                    </Reveal>
                                    <Reveal keyframes={fadeInRightShorter} delay={600} duration={1000}>
                                        <a href="" className='btn btn-primary'>Read more</a>
                                    </Reveal>
                                </div>
                                <div className="col-lg-8 col-md-6">
                                    <Reveal keyframes={fadeInUpShorter} delay={200} duration={1000}>
                                        <img src={require('./../assets/images/product-01.png')} className='w-100 mt-5' />
                                    </Reveal>
                                </div>
                            </div>
                        </div>
                        <div className="p-2">
                            <div className="row align-items-center">
                                <div className="col-lg-4 col-md-6 pt-lg-5">
                                    <Reveal keyframes={fadeInRightShorter} delay={200} duration={1000}>
                                        <h1 className='fw-lighter'>The One <b>That suits</b></h1>
                                        <h1>Your Needs</h1>
                                    </Reveal>
                                    <Reveal keyframes={fadeInRightShorter} delay={400} duration={1000}>
                                        <p className='fw-normal'>Dedicated to your health and hygiene Biodegradable cotton pads</p>
                                    </Reveal>
                                    <Reveal keyframes={fadeInRightShorter} delay={600} duration={1000}>
                                        <a href="" className='btn btn-primary'>Read more</a>
                                    </Reveal>
                                </div>
                                <div className="col-lg-8 col-md-6">
                                    <Reveal keyframes={fadeInUpShorter} delay={200} duration={1000}>
                                        <img src={require('./../assets/images/product-01.png')} className='w-100 mt-5' />
                                    </Reveal>
                                </div>
                            </div>
                        </div>
                    </Carousel>
                    <div className="banner-nav-buttons">
                        {
                            [...Array(totalSlides)].map((o, i) => (
                                <span key={i} className={`gradient-text mx-2 ${activeSlideIndex === i ? 'active-dote' : ''}`}>
                                    0{i + 1}
                                </span>
                            ))
                        }
                        <div className="d-inline-flex ms-3">
                            <PrevArrow />
                            <span className="vr mx-2 text-secondary"></span>
                            <NextArrow />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Banner