import React, { useState } from 'react'
import { BiSolidPhoneCall } from 'react-icons/bi'
import { FaFacebook, FaInstagram, FaYoutube } from 'react-icons/fa'
import { MdMailOutline } from 'react-icons/md'
import { TbWorldWww } from 'react-icons/tb'
import { Link } from 'react-router-dom'
import { HashLink } from 'react-router-hash-link'
import ContactModal from './ContactModal'


const Footer = () => {
    const [isOpen, setOpen] = useState(false)
    return (
        <footer id='contact-us'>
            <ContactModal show={isOpen} onHide={() => setOpen(!isOpen)} />
            <div className="bg-gradient footer-banner">
                <div className="container">
                    <div className="d-lg-flex align-items-end">
                        <div className="d-lg-flex align-items-end gap-3">
                            <img src={require('../assets/images/footer-girl.png')} className='z-index-2' width={200} />
                            <h2 className='fw-lighter text-white py-3'>
                                Your health comes first to us, get in touch
                                with us now and <b>Join the revolution</b>.
                            </h2>
                        </div>
                        <div className='col-lg-2 col p-0 text-lg-end text-center pb-lg-5 pb-3'>
                            <button onClick={() => setOpen(!isOpen)} className='btn-lg btn btn-primary'>Contact us</button>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-xl-4 col-lg-4 py-3">
                            <img src={require('../assets/images/footer-logo.png')} />
                            <p className='text-md text-secondary'>Biodegradable Cotton Pads. Perfect for your health and hygiene needs. Crafted from organic cotton, these pads are gentle on skin and the environment. Say goodbye to synthetic materials and hello to sustainability in your skincare routine.</p>
                            <div className="d-flex gap-4 pt-4">
                                <a target='_blank' href="https://www.instagram.com/fefine_official?igsh=M3RodWxrbHU0bWd2"><FaInstagram size={20} /></a>
                                <a target='_blank' href="https://www.facebook.com/profile.php?id=61557899527964"><FaFacebook size={20} /></a>
                                <a target='_blank' href="https://www.youtube.com/@fefinegreenviron"><FaYoutube size={20} /></a>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 py-3">
                            <h5 className='text-primary fw-bold'>Useful links</h5>
                            <div className="row">
                                <div className="col-6">
                                    <HashLink className='footer-link' to="/#">Home</HashLink>
                                    <HashLink className='footer-link' to="/#about-us">About us</HashLink>
                                    <HashLink className='footer-link' to="/#products">Our products</HashLink>
                                    <HashLink className='footer-link' to="/#contact-us" onClick={() => setOpen(!isOpen)}>Contact us</HashLink>
                                </div>
                                <div className="col-6">
                                    <Link to='/privacy-policy' className='footer-link'>Privacy policy</Link>
                                    <Link to='/privacy-policy' className='footer-link'>Terms of Service</Link>
                                    <Link to='/privacy-policy' className='footer-link'>Refund Policy</Link>
                                    <Link to='/privacy-policy' className='footer-link'>Shipping Policy</Link>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-3 col-lg-4 py-3">
                            <h5 className='text-primary fw-bold'>Contact Us</h5>
                            <address className='text-md text-secondary'>
                                Address: Plot No. 17, VIP Nagar Phase IV, Thirukachur, Maraimalai Nagar, Chennai-603209.
                                <div>Near: SP-Koil Railway station</div></address>
                            <div>
                                <a href="tel:8807855092" className='footer-link'><BiSolidPhoneCall className='text-primary' size={20} /> <span className='ms-2'>+91 88078 55092</span></a>
                                <hr className='my-2' />
                                <a href="mailto:care@fefine.in" className='footer-link'><MdMailOutline className='text-primary' size={20} /> <span className='ms-2'>care@fefine.in</span></a>
                                <hr className='my-2' />
                                <a href="/" className='footer-link'><TbWorldWww className='text-primary' size={20} /> <span className='ms-2'>www.fefine.in</span></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <center className='bg-gray text-light p-3'>
                Copyright @{new Date().getFullYear()} <a href="https://ejnarstudios.com/" className='text-white' target='_blank'>Ejnarstudios</a> | All rights reserved by Fefine
            </center>
        </footer>
    )
}

export default Footer